<template>
  <v-container fluid class="text-center">
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark color="secondary" elevation="2">
        <v-toolbar-title>
          Proveedores
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small @click="dialogs.new = true">
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
          Actualizar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </v-card-title>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template><span>Editar</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="getLogo(item.idproveedor)">mdi-image</v-icon></template><span>Logo</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="getContactRequests(item.idproveedor)">mdi-email-search</v-icon></template><span>Solicitudes de contacto</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="updateStatus(item.idproveedor)">mdi-refresh</v-icon></template><span>Activar / Desactivar</span></v-tooltip>
          <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idproveedor)">mdi-delete</v-icon></template><span>Eliminar</span></v-tooltip>
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <v-btn v-if="item.activo" x-small elevation="0" color="success" class="mx-1" rounded dark>Activado</v-btn>
          <v-btn v-else x-small elevation="0" color="grey" class="mx-1" rounded dark>Desactivado</v-btn>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
          No hay información disponible
        </template>
        <template v-slot:no-results>
          No se obtuvieron resultados
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="930px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nuevo proveedor</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.titulo" label="Título" :rules="rules.titulo" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete v-model="forms.new.categoria" :items="catalogues.categorias" label="Categoría" :rules="rules.titulo" :disabled="loading" persistent-hint required></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.subtitulo" label="Subtítulo" :rules="rules.subtitulo" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <p>Descripción</p>
                  <vue-editor v-model="forms.new.descripcion"></vue-editor>
                </v-col>
                <v-col cols="12">
                  <v-combobox v-model="forms.new.etiquetas" label="Etiquetas" :items="forms.new.etiquetas" :rules="rules.etiquetas" :disabled="loading" multiple chips required></v-combobox>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="forms.new.pagina_web" label="Página web" :rules="rules.pagina_web" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="forms.new.email" label="Correo" :rules="rules.email" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete v-model="forms.new.region" :items="catalogues.regiones" label="Región" :rules="rules.region" :disabled="loading" persistent-hint required></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="930px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar proveedor</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.titulo" label="Título" :rules="rules.titulo" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete v-model="forms.update.categoria" :items="catalogues.categorias" label="Categoría" :rules="rules.titulo" :disabled="loading" persistent-hint required></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.subtitulo" label="Subtítulo" :rules="rules.subtitulo" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <p>Descripción</p>
                  <vue-editor v-model="forms.update.descripcion"></vue-editor>
                </v-col>
                <v-col cols="12">
                  <v-combobox v-model="forms.update.etiquetas" label="Etiquetas" :items="forms.update.etiquetas" :rules="rules.etiquetas" :disabled="loading" multiple chips required></v-combobox>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="forms.update.pagina_web" label="Página web" :rules="rules.pagina_web" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="forms.update.email" label="Correo" :rules="rules.email" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete v-model="forms.update.region" :items="catalogues.regiones" label="Región" :rules="rules.region" :disabled="loading" persistent-hint required></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.logo" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Logo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="updateLogo()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formLogo" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="forms.logo.file" chips show-size  label="Seleccionar imagen..." accept="image/png" :disabled="loading"></v-file-input>
                </v-col>
                <v-col v-if="forms.logo.image" cols="12">
                  <v-card color="blue-grey">
                    <v-card-text>
                      <v-img :src="forms.logo.image" max-height="500" contain/>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el proveedor?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.detailRequests" persistent max-width="1000px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Solicitudes de contacto</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-data-table v-model="selectedSupplier" :headers="headersRequest" :items="itemsRequest" :loading="loading"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
              <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="removeContactRequest(item.id)">mdi-delete</v-icon></template><span>Eliminar solicitud</span></v-tooltip>
              </template>
              <template v-slot:[`footer.page-text`]="props">
                Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
              </template>
              <template v-slot:no-data>
                No hay información disponible
              </template>
              <template v-slot:no-results>
                No se obtuvieron resultados
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'
import { VueEditor } from "vue2-editor"
import _ from 'lodash'

export default {
  name: 'supplier',
  components: { VueEditor },
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    catalogues: {
      categorias: [],
      regiones: []
    },
    headers: [
      { text: 'Título', align: 'left', sortable: false, value: 'titulo' },
      { text: 'Categoría', align: 'left', sortable: false, value: 'categoria' },
      { text: 'Página web', align: 'left', sortable: false, value: 'pagina_web' },
      { text: 'Correo', align: 'left', sortable: false, value: 'email' },
      { text: 'Región', align: 'left', sortable: false, value: 'region' },
      { text: 'Activo', align: 'left', sortable: false, value: 'activo' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' },
    ],
    headersRequest: [
      { text: 'Empresa', align: 'left', sortable: false, value: 'empresa' },
      { text: 'Usuario', align: 'left', sortable: false, value: 'usuario' },
      { text: 'Fecha', align: 'left', sortable: false, value: 'fecha' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' },
    ],
    items: [],
    itemsRequest: [],
    selected: [],
    selectedSupplier: [],
    deleted: null,
    forms: {
      new: {
        titulo: '',
        subtitulo: '',
        descripcion: '',
        categoria: '',
        etiquetas: [],
        pagina_web: '',
        email: '',
        region: ''
      },
      update: {
        idproveedor: '',
        titulo: '',
        subtitulo: '',
        descripcion: '',
        categoria: '',
        etiquetas: [],
        pagina_web: '',
        email: '',
        region: ''
      },
      logo: {
        proveedor: null,
        file: null,
        image: null
      }
    },
    rules: {
      titulo: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 50) || 'El campo excede la longitud máxima'
      ],
      subtitulo: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 200) || 'El campo excede la longitud máxima'
      ],
      descripcion: [],
      categoria: [
        v => !!v || 'El campo es requerido'
      ],
      etiquetas: [
        v => (!_.isNil(v) && !_.isEmpty(v)) || 'El campo es requerido'
      ],
      pagina_web: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 200) || 'El campo excede la longitud máxima',
        v => /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(v) || 'El campo es inválido'
      ],
      email: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 320) || 'El campo excede la longitud máxima',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'El campo es inválido'
      ],
      region: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 50) || 'El campo excede la longitud máxima'
      ],
    },
    dialogs: {
      new: false,
      update: false,
      logo: false,
      detailRequests: false,
      remove: false
    }
  }),
  mounted() {
    this.getCatalogues()
    this.refresh()
  },
  methods: {
    async getCatalogues() {
      const category = await this.axios.get(services.routes.supplier + '/category')
      if (category.data.data) {
        this.catalogues.categorias = category.data.data
      }
      const region = await this.axios.get(services.routes.supplier + '/region')
      if (region.data.data) {
        this.catalogues.regiones = region.data.data
      }
    },
    refresh() {
      this.loading = true
      this.axios.get(services.routes.supplier)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset()
        for (let key in this.forms.new) {
          if (key === 'etiquetas') {
            this.forms.new[key] = []
          }
          else {
            this.forms.new[key] = ''
          }
        }
        this.dialogs.new = false
      }
      else if (this.dialogs.update) {
        this.$refs.formUpdate.reset()
        for (let key in this.forms.update) {
          if (key === 'etiquetas') {
            this.forms.update[key] = []
          }
          else {
            this.forms.update[key] = ''
          }
        }
        this.dialogs.update = false
      }
      else if (this.dialogs.logo) {
        this.$refs.formLogo.reset()
        this.forms.logo = {
          proveedor: null,
          file: null,
          image: null
        }
        this.dialogs.logo = false
      }
      else if (this.dialogs.remove) {
        this.dialogs.remove = false
      }
      else if (this.dialogs.detailRequests) {
        this.itemsRequest = []
        this.selectedSupplier = []
        this.dialogs.detailRequests = false
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.new))
        this.axios.post(services.routes.supplier, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Proveedor creado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data) {
      this.forms.update = {
        idproveedor: data.idproveedor,
        titulo: data.titulo,
        subtitulo: data.subtitulo,
        descripcion: data.descripcion,
        categoria: data.categoria,
        etiquetas: data.etiquetas,
        pagina_web: data.pagina_web,
        email: data.email,
        region: data.region
      }
      this.dialogs.update = true
    },
    getLogo(id) {
      this.loading = true
      this.axios.get(services.routes.supplier + '/logo?id=' + id)
        .then(response => {
          let data = response.data.data
          this.forms.logo.proveedor = id
          if (data) {
            data = data.data
            this.forms.logo.image = 'data:image/png;base64,' + btoa(new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
          }
          this.dialogs.logo = true
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateLogo() {
      if (this.forms.logo.file) {
        let fr = new FileReader();
        let file = this.forms.logo.file
        let size = file.size
        if (size <= 1000000) {
          fr.onload = () => {
            this.loading = true
            let data = {
              idproveedor: this.forms.logo.proveedor,
              logo: fr.result.split(',')[1]
            }
            this.axios.put(services.routes.supplier + '/logo', { data })
              .then(response => {
                this.cancel()
                this.refresh()
                this.success = true
                this.msgSuccess = 'Logo actualizado exitosamente'
              })
              .catch(error => {
                this.error = true
                this.msgError = error.response.data.message || 'Servicio no disponible'
              })
              .finally(() => {
                this.loading = false
              })
          }
          fr.onerror = () => {
            this.loading = false
            this.forms.logo.file = null
            this.error = true
            this.msgError = 'El archivo no fue leído correctamente'
          }
          fr.readAsDataURL(file);
        }
        else {
          this.error = true
          this.msgError = 'El archivo ha excedido el límite permitido (1 MB)'
        }
      }
      else {
        this.error = true
        this.msgError = 'Seleccionar un archivo'
      }
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        this.axios.put(services.routes.supplier, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Proveedor actualizado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    getContactRequests(id) {
      this.loading = true
      this.axios.get(services.routes.supplier + '/request?id=' + id)
        .then(response => {
          this.itemsRequest = response.data.data
          this.dialogs.detailRequests = true
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    removeContactRequest(id) {
      this.loading = true
      this.axios.delete(services.routes.supplier + '/request', { data: { data: { idregistro_proveedor: id } } })
        .then(response => {
          this.cancel()
          this.refresh()
          this.success = true
          this.msgSuccess = 'Solicitud de contacto eliminada exitosamente'
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateStatus(id) {
      this.loading = true
      this.axios.put(services.routes.supplier + '/updateStatus', { data: { idproveedor: id } })
        .then(response => {
          this.cancel()
          this.refresh()
          this.success = true
          this.msgSuccess = 'Proveedor actualizado exitosamente'
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    remove(id) {
      if (!id) {
        this.loading = true
        let id = this.deleted
        this.axios.delete(services.routes.supplier, { data: { data: { idproveedor: this.deleted } } })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Proveedor eliminado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.deleted = id
        this.dialogs.remove = true
      }
    }
  }
}
</script>

<style scoped>
</style>
