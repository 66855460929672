const host = process.env.VUE_APP_API_SERVER;

const routes = {
  user: host + '/api/admin/user',
  company: host + '/api/admin/company',
  item: host + '/api/admin/company/item',
  role: host + '/api/admin/role',
  emissionSource: host + '/api/admin/emissionSource',
  professionalSector: host + '/api/admin/professionalSector',
  catalogue: host + '/api/admin/catalogue',
  template: host + '/api/admin/template',
  import: host + '/api/admin/import',
  supplier: host + '/api/admin/supplier',
  form: host + '/api/admin/form',
  responsibilities: host + '/api/admin/responsibilities'
};

export default {
  routes
};
